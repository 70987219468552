@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&family=Fredoka:wght@300..700&family=Fresca&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Baloo 2';
}


/* Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* display: none; */
}

.custom-scrollbar:hover::-webkit-scrollbar {
  display: block; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  /* background: #BCBCBC; */
  background: #C8EAFF;
  /* background: #86D8FF; */
  border-radius: 8px;
}


/* **************************************************************************************/

.frame-container {
  position: relative;
}

.frame {
  width: 100%;
  height: 100%;
}

.img_content {
  position: absolute;
  top: 10%; /* Adjust this to position the image correctly inside the frame */
  left: 10%; /* Adjust this to position the image correctly inside the frame */
  width: 80%; /* Adjust this to fit the image within the frame */
  height: 80%; /* Adjust this to fit the image within the frame */
  object-fit: cover; /* Ensures the image covers the area properly */
}


/* *********************************************************************************************/

.dot-scrollbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

.dot-scrollbar .dot {
  width: 12px;
  height: 12px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #d9d9d9;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot-scrollbar .dot.active {
  background-color: 
  #5BC9FE;
}


/* *********************************************************************************************/

@keyframes popOut {
  0% {
    transform: translateY(100%) scale(0);
    opacity: 0;
  }
  60% {
    transform: translateY(-20%) scale(1.2);
    opacity: 1;
  }
  80% {
    transform: translateY(10%) scale(0.9);
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}
.pop-out-card {
  animation: popOut 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
  opacity: 0;
  z-index: 5;
}

.pop-out-card:hover {
  z-index: 20; /* Higher z-index on hover */
}

#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  /* display: none;  */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 3.7em;
}

@media screen and (max-width: 768px) {
  #messages {
    font-size: 2.0em;
  }
}